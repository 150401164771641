var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nine" },
    [
      _c("Loading", { attrs: { visible: _vm.loading } }),
      _c(
        "div",
        { staticClass: "banner w_1200", attrs: { "bi-route": "fengqiang" } },
        [
          _vm._m(0),
          _c("div", { staticClass: "center" }, [
            _vm._m(1),
            _c("div", { staticClass: "swiper" }, [
              _c("div", { staticClass: "swiper-container" }, [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(_vm.hotGoodsList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "swiper-slide",
                        attrs: {
                          "bi-click": "goods",
                          "data-index": index,
                          "data-gid": item.id || item.goodsId
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "content",
                            on: {
                              click: function($event) {
                                return _vm.handleItemClick(item)
                              }
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "marketgroup",
                                    rawName: "v-marketgroup",
                                    value: item,
                                    expression: "item"
                                  }
                                ],
                                staticClass: "pic"
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: item.mainPic + "_310x310.jpg",
                                      expression:
                                        "`${item.mainPic}_310x310.jpg`"
                                    }
                                  ],
                                  attrs: { alt: "" }
                                })
                              ]
                            ),
                            _c("div", { staticClass: "price" }, [
                              _c("div", { staticClass: "priceLeft" }, [
                                _vm._v("￥"),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("salesNum")(item.actualPrice))
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "priceRight" }, [
                                _vm._v("疯抢"),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("salesNum")(
                                        item.monthSales,
                                        _vm._,
                                        1
                                      )
                                    )
                                  )
                                ]),
                                _vm._v("件")
                              ])
                            ]),
                            _c(
                              "a",
                              {
                                staticClass: "text",
                                attrs: {
                                  href:
                                    "?r=/l/d&nav_wrap=nine&id=" +
                                    (item.id || item.goodsId),
                                  target: "_blank",
                                  title: item.dtitle || item.title
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.dtitle || item.title))]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _c("div", {
                  staticClass: "swiper-button-prev",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                }),
                _c("div", {
                  staticClass: "swiper-button-next",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                })
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "nineContainer w_1200" },
        [
          _vm._m(2),
          _c("div", { staticClass: "navLink", attrs: { "bi-route": "tags" } }, [
            _c(
              "div",
              { staticClass: "box" },
              _vm._l(_vm.linkList, function(item) {
                return _c(
                  "a",
                  {
                    key: item.value,
                    class: [
                      "link",
                      item.value === _vm.linkTabsIndex ? "active" : ""
                    ],
                    attrs: {
                      "bi-click": "nav",
                      "data-cid": item.value,
                      "data-name": item.lable
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleChangeTabs(item.value)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.lable))]
                )
              }),
              0
            )
          ]),
          _c(
            "div",
            { staticClass: "goodList", attrs: { "bi-route": "jingxuan" } },
            [
              _c(
                "ul",
                _vm._l(_vm.goodsList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: item.id,
                      attrs: {
                        "bi-click": "goods",
                        "data-index": index,
                        "data-cid": _vm.linkTabsIndex,
                        "data-gid": item.id || item.goodsId
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleItemClick(item)
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "marketgroup",
                              rawName: "v-marketgroup",
                              value: item,
                              expression: "item"
                            }
                          ],
                          staticClass: "img"
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: item.mainPic + "_310x310.jpg",
                                expression: "`${item.mainPic}_310x310.jpg`"
                              }
                            ],
                            attrs: { alt: "" }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "goodsPadding" }, [
                        _c("div", { staticClass: "title" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "?r=/l/d&nav_wrap=nine&id=" +
                                  (item.id || item.goodsId),
                                target: "_blank",
                                title: item.dtitle || item.title
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              item.shopType == 1
                                ? _c("i", {
                                    class: [
                                      "tag",
                                      item.haitao
                                        ? "tagTmgj"
                                        : item.tchaoshi
                                        ? "tagTmc"
                                        : "tagTmail"
                                    ],
                                    attrs: {
                                      title: item.haitao
                                        ? "天猫国际"
                                        : item.tchaoshi
                                        ? "天猫超市"
                                        : "天猫"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(
                                " " + _vm._s(item.dtitle || item.title) + " "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "goodsNumType" }, [
                          _c("span", { staticClass: "oldPrice" }, [
                            _vm._v(
                              "原价 " +
                                _vm._s(_vm._f("salesNum")(item.originalPrice))
                            )
                          ]),
                          _c("span", { staticClass: "goodsNum" }, [
                            _vm._v(
                              "销量 " +
                                _vm._s(
                                  _vm._f("salesNum")(item.monthSales, _vm._, 1)
                                )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "couponWarp" }, [
                          _c("span", { staticClass: "price" }, [
                            _c("div", { staticClass: "tip" }),
                            _c("i", [_vm._v("￥")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("salesNum")(item.actualPrice))
                              )
                            ])
                          ]),
                          item.couponPrice
                            ? _c("b", { staticClass: "coupon" }, [
                                _c("i", [
                                  _vm._v(_vm._s(item.couponPrice) + " ")
                                ]),
                                _vm._v("元券 ")
                              ])
                            : _vm._e()
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _c("Pagination", {
            attrs: {
              pageSize: _vm.page.pageSize,
              total: _vm.pageTotal,
              current: _vm.page.pageId
            },
            on: { onchange: _vm.handlePageChange }
          })
        ],
        1
      ),
      _vm.visible
        ? _c("MicroDetail", {
            attrs: { visible: _vm.visible, id: _vm.microDetailId },
            on: { onClose: _vm.handleMicroDetailClose }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _c("img", { attrs: { src: require("./images/nine_logo.png"), alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("img", {
        attrs: { src: require("./images/nine_tabicon.png"), alt: "" }
      }),
      _vm._v("近一小时疯抢")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "nineH3" }, [
      _c("span"),
      _vm._v("9.9包邮精选 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }