<template>
    <div class="nine">
          <Loading :visible="loading" />
        <div class="banner w_1200" bi-route="fengqiang">
            <h1><img src="./images/nine_logo.png" alt=""></h1>
            <div class="center">
                <h3><img src="./images/nine_tabicon.png" alt="">近一小时疯抢</h3>
                <div class="swiper">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div
                                class="swiper-slide"
                                v-for="(item, index) in hotGoodsList"
                                :key="index"
                                bi-click="goods"
                                :data-index="index"
                                :data-gid="item.id || item.goodsId"
                            >
                                <div class="content" @click="handleItemClick(item)">
                                    <a class="pic" v-marketgroup="item" >
                                        <img v-lazy="`${item.mainPic}_310x310.jpg`" alt="">
                                    </a>
                                    <div class="price">
                                        <div class="priceLeft">￥<span>{{item.actualPrice | salesNum}}</span></div>
                                        <div class="priceRight">疯抢<span>{{item.monthSales | salesNum(_,1)}}</span>件</div>
                                    </div>
                                    <a class="text" :href="`?r=/l/d&amp;nav_wrap=nine&amp;id=${item.id || item.goodsId}`" target="_blank" @click.stop="" :title="item.dtitle || item.title">{{item.dtitle || item.title}}</a>
                                </div>
                            </div>
                        </div>
                        <!-- 如果需要导航按钮 -->
                        <div class="swiper-button-prev" @click.stop=""></div>
                        <div class="swiper-button-next" @click.stop=""></div>
                    </div>
                </div>
            </div>
        </div>
         <!-- 商品列表 start-->
            <div class="nineContainer w_1200">
                <h3 class="nineH3">
                    <span></span>9.9包邮精选
                </h3>
                <div class="navLink" bi-route="tags">
                    <div class="box">
                        <a bi-click="nav" :data-cid="item.value" :data-name="item.lable" @click="handleChangeTabs(item.value)" v-for="item in linkList" :key="item.value" :class="['link', item.value === linkTabsIndex ? 'active' : '']">{{item.lable}}</a>
                    </div>
                </div>
                <div class="goodList" bi-route="jingxuan">
                    <ul>
                        <li bi-click="goods" :data-index="index" :data-cid="linkTabsIndex" :data-gid="item.id || item.goodsId" v-for="(item, index) in goodsList" :key="item.id" @click="handleItemClick(item)">
                            <a class="img" v-marketgroup="item" ><img v-lazy="`${item.mainPic}_310x310.jpg`" alt=""></a>
                            <div class="goodsPadding">
                                <div class="title">
                                    <a :href="`?r=/l/d&amp;nav_wrap=nine&amp;id=${item.id || item.goodsId}`" target="_blank" @click.stop="" :title="item.dtitle || item.title">
                                        <i v-if="item.shopType == 1" :class="['tag', item.haitao ? 'tagTmgj' : item.tchaoshi ? 'tagTmc' : 'tagTmail']" :title="item.haitao ? '天猫国际' : item.tchaoshi ? '天猫超市' : '天猫'"></i>
                                        {{item.dtitle || item.title}}
                                    </a>
                                </div>
                                <div class="goodsNumType">
                                    <span class="oldPrice">原价 {{item.originalPrice | salesNum}}</span>
                                    <span class="goodsNum">销量 {{item.monthSales | salesNum(_,1)}}</span>
                                </div>
                                <div class="couponWarp">
                                    <span class="price">
                                        <div class="tip"></div>
                                        <i>￥</i>
                                        <span>{{item.actualPrice | salesNum}}</span>
                                    </span>
                                    <b class="coupon" v-if="item.couponPrice">
                                        <i>{{item.couponPrice}} </i>元券
                                    </b>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <Pagination
                    :pageSize="page.pageSize"
                    :total="pageTotal"
                    :current="page.pageId"
                    @onchange="handlePageChange"
                    />
            </div>
        <!-- 商品列表 end-->
        <MicroDetail @onClose="handleMicroDetailClose" v-if="visible" :visible="visible" :id="microDetailId" />
    </div>
</template>

<script>
import { importCDN } from "@/utils/utils";
import { getNineList } from './services/index'
import Loading from "@/components/loading";
import { Pagination } from "../search/components";
import MicroDetail from "@/components/MicroDetail";
import { mapGetters } from "vuex";
export default {
    components: {
        Pagination,
        MicroDetail,
        Loading
    },
    computed: {
        ...mapGetters(["config"])
    },
    mounted () {
        if(this.config.site_name){
        document.title = `9块9包邮_${this.config.site_name}`;
        }
        importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(
            () => {
                var promise = new Promise((resolve, reject) => {
                    this.getHeaderList(this.linkTabsIndex)
                    this.getHeaderList(this.linkTabsIndex, true)
                    
                    setTimeout(() => {
                        resolve(1)
                    }, 500)
                });
                promise.then( res => {
                    this.mySwiper = new Swiper(".swiper-container", {
                        // loop: true, // 循环模式选项
                        direction: "horizontal",
                        slidesPerView: 'auto',
                        slidesPerGroup : 5,

                        // 如果需要前进后退按钮
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                    });
                    // this.mySwiper.update()
                })
            }
        );
    },
    destroyed () {
        if(this.mySwiper){
            this.mySwiper.destroy(false)  
        }
    },
    data () {
        return {
            mySwiper:false,
             loading: true,
            hotGoodsList: [],
            linkList: [
                {
                    lable: '推荐',
                    value: '-1'
                },
                {
                    lable: '5.9元区',
                    value: '1'
                },
                {
                    lable: '9.9元区',
                    value: '2'
                },
                {
                    lable: '19.9元区',
                    value: '3'
                },
            ],
            goodsList: [],
            linkTabsIndex: '-1', //精选
            pageTotal: '',
            page: {
                pageId: 1,
                pageSize: 100,
            },
            visible: false,
            microDetailId: "",
        }
    },
    methods: {
        init: async function(){

        },
        getHeaderList: function(nineCid, topNum = false){
            // topNum 是否是一小时疯抢
            this.loading = true
            if(topNum){
                var params = {
                    nineCid: 2,
                    pageSize: 50,
                    pageId: 1
                }
                getNineList(params).then( res => {
                    this.loading = false
                    this.hotGoodsList = res.data.list || []
                })
            }else{
                var params = {
                    nineCid,
                    ...this.page
                }
                getNineList(params).then( res => {
                    this.loading = false
                    this.goodsList = res.data.list || []
                    this.pageTotal = res.data.totalNum > 10000 ? 10000 : res.data.totalNum ;
                })
            }
        },
        handleChangeTabs: function(value){
            this.linkTabsIndex = value
            this.page.pageId = 1
            this.getHeaderList(value)
        },
        handlePageChange: function(current) {
            if (current < 1 || current > this.pages) return;
            this.page = {
                ...this.page,
                pageId: current,
            }
            this.getHeaderList(this.linkTabsIndex)
        },
          //点击商品
        handleItemClick(item) {
            if (!this.config.micro_switch) {
                window.open(
                `?r=l/d&u=1&id=${item.id || item.goodsId}&from=dtk`,
                "_blank"
                );
            }else{
                this.visible = true
                this.microDetailId = item.id || item.goodsId
            }
            
        },
        handleMicroDetailClose() {
            this.visible = false
            this.microDetailId = null
        }
    },
}
</script>

<style scoped lang="less">
@import "https://public.ffquan.cn/lib/swiper/css/swiper.min.css";
.nine {
    padding: 0;
    margin-top: -15px;
    .banner {
        height: 460px;
        background: url('./images/nine_tabbg.png') top center no-repeat;
        margin-bottom: 50px;
        position: relative;
        top: 30px;
        border-radius: 12px;
        background-size: 100% 100%;
        h1 {
            height: 58px;
            text-align: center;
            padding: 27px 0 23px;
            img {
                height: 58px;
            }
        }
        .center {
            margin: 0 15px;
            h3{
                color: #fff;
                line-height: 26px;
                height: 26px;
                font-size: 18px;
                margin: 0;
                img {
                    float: left;
                    height: 26px;
                    margin-right: 10px;
                }
            } 
            .swiper {
            padding: 15px 0;
            .swiper-container {
                margin: 0 auto;
                position: relative;
                overflow: hidden;
                list-style: none;
                padding: 0;
                z-index: 1;
                .swiper-wrapper {
                    // transform: translate3d(0px, 0px, 0px);
                    .swiper-slide {
                        width: 210px;
                        height: 270px;
                        .content {
                            margin-right: 10px;
                            height: 270px;
                            background: #fff;
                            border-radius: 10px;
                            .pic {
                                display: block;
                                height: 180px;
                                width: 180px;
                                margin: auto auto 20px;
                                overflow: hidden;
                                position: relative;
                                top: 10px;
                                z-index: 1;
                                font-style: normal;
                                background: #333;
                                img {
                                    height: 180px;
                                    width: 180px;
                                    background-size: 100% 100%;
                                }
                            }
                            .price {
                                padding: 10px 10px 5px 12px;
                                height: 20px;
                                line-height: 20px;
                                overflow: hidden;
                                display: flex;
                                justify-content: space-between;
                                .priceLeft {
                                    font-size: 20px;
                                    font-weight: 400;
                                    color: #FC4D52;
                                }
                                .priceRight {
                                    color: #888;
                                    font-size: 14px;
                                    font-weight: 400;
                                    span {
                                        color: #FF8433;
                                        font-weight: 700;
                                    }
                                }
                            }
                            .text {
                                margin: 0 10px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                height: 22px;
                                line-height: 22px;
                                color: #494949;
                                display: block;
                            }
                        }
                    }
                    .swiper-slide:hover .text {
                        color: #FC4D52;
                        cursor: pointer;
                    }
                    
                }
                .swiper-button-prev {
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    z-index: 20;
                    background: url('./images/prev.png');
                    background-size: 100% 100%;
                }
                .swiper-button-next {
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    z-index: 20;
                    background: url('./images/next.png');
                    background-size: 100% 100%;
                }
                .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
                    pointer-events: auto;
                }
            }
        }
        }
    }
    .nineContainer {
        background: #fff;
        padding-bottom: 1px;
        margin-bottom: 30px;
        .nineH3{
            height: 20px;
            padding: 15px 13px;
            line-height: 20px;
            font-size: 18px;
            color: #555;
            border-bottom: solid 1px #f1f1f1;
            span {
                width: 4px;
                height: 20px;
                background: #FE2E54;
                float: left;
                margin-right: 8px;
                overflow: hidden;
            }
        }
        .navLink {
            position: relative;
            z-index: 0;
            padding-right: 100px;
            padding-bottom: 10px;
            border-bottom: solid 1px #f1f1f1;
            .box {
                overflow: hidden;
                height: 40px;
                .link {
                    display: inline-block;
                    padding: 1px 8px;
                    border-radius: 15px;
                    position: relative;
                    top: 15px;
                    z-index: 1;
                    font-size: 14px;
                    height: 20px;
                    line-height: 20px;
                    margin: 0 0 10px 10px;
                }
                .active {
                    color: #fff;
                    background: #FE2E54;
                    border-radius: 15px;
                    z-index: 0;
                }
            }
        }
        .goodList {
            background: #fff;
            margin: 0 auto 30px;
            padding: 0 0 1px;
            width: 1200px;
            font-size: 12px;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                li {
                    margin: 0 -1px -1px 0;
                    width: 241px;
                    height: 340px;
                    border: 1px solid #f1f1f1;
                    padding: 15px;
                    box-sizing: border-box;
                    position: relative;
                    background: #fff;
                }
                li:hover {
                    box-shadow: 0 0 15px rgba(0,0,0,.1);
                    overflow: hidden;
                    border-color: #FE2E54!important;
                    position: relative;
                    z-index: 1;
                    zoom: 1;
                }
                li a.img {
                    display: block;
                    width: 210px;
                    height: 210px;
                    overflow: hidden;
                    position: relative;
                    opacity: .85;
                    img{
                        width: 210px;
                        height: 210px;
                        background-size: 100% 100%;
                    }
                }
                li .goodsPadding {
                    padding: 0;
                    margin-top: 18px;
                    width: 210px;
                    .title {
                        color: #494949;
                        line-height: 18px;
                        height: 18px;
                        margin-bottom: 11px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        a {
                            color: #494949;
                            font-size: 14px;
                            text-decoration: none;
                            cursor: pointer;
                        }
                        a:hover {
                            color: #FC4D52;
                        }
                        a .tagTmail {
                            background: url(./images/tmall.png) center no-repeat;
                            background-size: contain;
                        }
                        a .tagTmgj {
                            background: url(./images/tmgj.png) center no-repeat;
                            background-size: contain;
                        }
                        a .tagTmc {
                            background: url(./images/maoc.png) center no-repeat;
                            background-size: contain;
                        }
                        a .tag {
                            height: 18px;
                            width: 15px;
                            float: left;
                            margin: 0 5px 0 0;
                        }
                    }
                    .goodsNumType {
                        height: 18px;
                        color: #b0b0b0;
                        font-size: 12px;
                        display: block;
                        position: relative;
                        line-height: 16px;
                        .oldPrice {
                            float: left;
                        }
                        .goodsNum {
                            float: right;
                        }
                    }
                    .couponWarp {
                        position: relative;
                        height: 26px;
                        display: block;
                        width: 100%;
                        margin-bottom: 12px;
                        i {
                            font-style: normal;
                        }
                        .price {
                            color: #fc4d52;
                            font-size: 18px;
                            height: 35px;
                            line-height: 35px;
                            margin: 5px 0 0 -4px;
                            display: inline-block;
                            .tip {
                                display: inline;
                                font-size: 12px;
                                font-family: MicrosoftYaHei;
                                color: rgba(252,77,82,1);
                                line-height: 16px;
                                position: relative;
                                bottom: 1px;
                            }
                            i {
                                font-size: 18px;
                                position: relative;
                                top: -1px;
                            }
                            span {
                                font-size: 24px;
                                font-weight: 700;
                                font-family: Arial;
                            }
                        }
                        .coupon {
                            line-height: 14px;
                            background: url('../halfProceDaily/images/quan.png') center rgba(255,248,248,1);
                            background-size: 100% 100%;
                            border-radius: 2px;
                            font-weight: 400;
                            padding: 3px 7px;
                            margin: 13px 0;
                            position: relative;
                            border: none;
                            height: auto;
                            color: #fc4d52;
                            font-family: "microsoft Yahei";
                            min-width: 57px;
                            text-align: center;
                            box-sizing: border-box;
                            float: right;
                            i {
                                font-family: Arial;
                                font-size: 15px;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.w_1200{
    max-width: 1200px;
    margin: 0 auto;
}
</style>